html,
body {
    min-width: 320px;
    min-height: 100vh;
    margin: 0;
}

.no-scroll {
    position: relative;
    overflow: hidden;
}